<template>
  <div class="rounded-lg" style="overflow: hidden">
    <!-- Die Karte mit vue3-leaflet -->
    <l-map v-if="showMap" style="width: 100%; height: 300px" :zoom="zoom" :center="center">
      <!-- Verwende die OpenStreetMap Tiles -->
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <!-- Marker mit individuellem Icon -->
      <l-marker
        v-for="marker in markers"
        :key="marker.title || marker.position.lat + marker.position.lng"
        :lat-lng="[marker.position.lat, marker.position.lng]"
        :divIconOptions="customIcon"
        @click="showStudio(marker)" />
    </l-map>

    <!-- Dialog zum Anzeigen der Studio-Details -->
    <v-dialog persistent :model-value="selectedStudio !== null">
      <v-card v-if="selectedStudio" class="mx-auto" max-width="300px">
        <v-card-title style="background-color: #00963f" class="text-white font-weight-bold">
          {{ selectedStudio.name }}
        </v-card-title>
        <Location class="mt-2" :hideTitle="true" :studio="selectedStudio" />
        <v-card-actions>
          <v-btn @click="selectedStudio = null" variant="text">schliessen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$router.push('/clubs/' + selectedStudio.code)" variant="elevated" color="primary"> zum Studio </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { LMap, LTileLayer, LMarker } from "vue3-leaflet"
import L from "leaflet"
import Location from "@/components/club/Location.vue"

export default {
  components: { LMap, LTileLayer, LMarker, Location },
  data() {
    return {
      // Als Array: [lat, lng]
      center: [1, 1],
      markers: [],
      // Behalte die Pin-Optionen bei
      pinOptions: { background: "#00963f", glyphColor: "#FFFFFF", borderColor: "#313131" },
      selectedStudio: null,
      showMap: false,
      customIcon: null,
      zoom: 8,
    }
  },
  async created() {
    await this.fetchStudios()

    this.markers = this.studios.map((e) => {
      if (!e.location) return { position: { lat: 47.54789, lng: 8.89278 } }
      return { position: { lat: e.location.lat, lng: e.location.long }, title: e.name }
    })

    // Berechne das Kartenmittel (Center) als [lat, lng]
    this.center = [Math.min(...this.markers.map((e) => e.position.lat)) + 0.4, Math.max(...this.markers.map((e) => e.position.lng)) - 0.9]
    // Custom Icon als feste Instanz definieren:
    this.customIcon = {
      className: "custom-marker",
      html: `<div class="custom-marker-icon"></div>`,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
    }

    this.showMap = true
  },
  computed: {
    ...mapState({
      studios: (state) => state.studios,
      loadingStudios: (state) => state.loadingStudios,
      loadingStudiosError: (state) => state.loadingStudiosError,
    }),
  },
  methods: {
    ...mapActions(["fetchStudios"]),
    showStudio(marker) {
      const studio = this.studios.find((e) => e.name === marker.title)
      if (studio) {
        this.selectedStudio = studio
      }
    },
  },
}
</script>

<style lang="scss">
/* Optional: Falls du zusätzliches Styling für Leaflet-Elemente möchtest */
.custom-marker {
  /* Hier kannst du weitere Marker-Stile definieren */
}
.custom-marker-icon {
  background: #00963f;
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Falls du Elemente von Leaflet ausblenden möchtest, ähnlich wie bei Google Maps */
.leaflet-control-container {
  display: none;
}
.leaflet-map-container {
  height: 300px !important;
}
</style>
